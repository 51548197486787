import React from 'react'
import { Text } from 'rebass/styled-components'

import Section from './section'
import Container from './container'

const AboutUs = () => {
  return (
    <Section>
      <Container>
        <Text variant="body" as="p">
          Naše společnost se zaměřuje na komplexní dodávky ve stavebnictví a
          tepelné energetice pro objekty občanské a průmyslové výstavby.
        </Text>

        <Text variant="body" as="p" sx={{ pb: 0 }}>
          Na výše uvedené práce má společnost Czech heat service s.r.o. veškerá
          nezbytná osvědčení a živnostenské listy, které spolu se zkušenými
          pracovníky tvoří dobrý důvod proč je právě Czech heat service s.r.o.
          pro Vás tou správnou volbou.
        </Text>
      </Container>
    </Section>
  )
}

export default AboutUs
