import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Element } from 'react-scroll'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import AboutUs from '../components/about-us'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Dodávky ve stavebnictví a tepelné energetice" />

      <Hero
        title="Budujeme cesty k teplu..."
        subtitle="Komplexní dodávky ve stavebnictví a tepelné energetice pro objekty občanské a průmyslové výstavby."
        image={data.image}
        ctaLabel="Více informací"
        ctaLink="/"
        fullHeight
        smoothLabel="Více informací"
        smoothLink="about"
      />

      <Element name="about" className="about">
        <AboutUs />
      </Element>
    </Layout>
  )
}

export default IndexPage
